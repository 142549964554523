import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { GlobalService, QBBackendReportableError } from '../services/global.service';
import { QBResponsePacket } from '../shared/qb-response-packet.class';
import { ErrorReportService } from '../services/error/error-report.service';

/** Pass untouched request through to the next request handler. */
@Injectable()
export class ReplyInterceptor implements HttpInterceptor {
    private oldVersion = false;

    constructor(protected router: Router, protected glob: GlobalService, private err: ErrorReportService) { }

    get debug(): boolean { return this.glob.debug.http; }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (this.debug) console.log('ReplyInterceptor.intercept:\n  Forwarding request as is:\n  ', req);
        return next.handle(req.clone({
            setHeaders: {
                "Authorization": `Bearer ${this.glob.getToken()}`
            }
        }))//.clone({ setHeaders: { Authorization: `Bearer ${this.glob.getToken}` } })
            .pipe(
                tap(event => {
                    if (event instanceof HttpResponse) {
                        if (req.method == 'POST') {
                            if (this.debug) console.log('ReplyInterceptor.intercept > tap:\n  Checking response status and app version, and forwarding as is:\n  ', event);
                            this.checkResponse(event, req);
                        } else {
                            if (this.debug) console.log('ReplyInterceptor.intercept > tap:\n  Response to a non POST request, forwarding as is:\n  ', event);
                        }
                    }
                })
            );
    }

    private checkResponse(event: HttpResponse<QBResponsePacket>, req: HttpRequest<any>) {

 
        let body = event.body;
        if (this.debug) console.log('ReplyInterceptor.checkResponse:\n  event.body.status:\n  ', body.statusCode);

        // if (body.updated && !this.oldVersion) {
        //     this.oldVersion = true;
        //     this.glob.notify('קיים עדכון גרסא למערכת.\n\nנא לרענן את חלון הדפדפן בהקדם על מנת למנוע שגיאות.', { type: 'info', timeout: 0 });
        // }

        if (body.statusCode !== 'OK') {
            const err = new QBBackendReportableError(body.message);
            let report = true;
            let alert_user = true;
            switch (body.statusCode) {
                case 'ERROR':
                    err.source = 'backend';
                    break;
                case 'USER_ERROR':
                    err.source = 'user_reportable';
                    break;
                // case 'SESSION_ERROR'://php session doesn't exist
                //     if (sessionStorage.getItem('uData') === 'LOGGEDOUT') {
                //         report = false;
                //         alert_user = false;
                //     } else {
                //         this.err.logout(body.statusCode);
                //         if (this.router) this.router.navigate(['/logout']);
                //     }
                //     err.source = 'php_session';
                //     break;
                case 'ALERT':
                    report = false;
                    break;
            }
            if (report) this.err.reportBackendError(req, err);
            if (alert_user) this.glob.alert(err.message);
        }
    }
}
