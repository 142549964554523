import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { trackByRoute } from '../../utils/track-by';
import { NavigationService } from '../../services/navigation.service';
import icRadioButtonChecked from '@iconify/icons-ic/twotone-radio-button-checked';
import icRadioButtonUnchecked from '@iconify/icons-ic/twotone-radio-button-unchecked';
import { LayoutService } from '../../services/layout.service';
import { MatDialog } from '@angular/material/dialog';
import icPlus from '@iconify/icons-ic/twotone-plus';
import icAssigment from '@iconify/icons-ic/twotone-assignment';
import icSettings from '@iconify/icons-ic/twotone-settings';
import graphUpArrow from '@iconify/icons-bi/graph-up-arrow';
// import  from '@iconify/icons-ic/gra';
// import eggsIcon from '@iconify/icons-jam/eggs';
// import magnifyingGlass from '@iconify/icons-entypo/magnifying-glass';
// import meatIcon from '@iconify/icons-tabler/meat';
// import chickenIcon from '@iconify/icons-icon-park-twotone/chicken';
// import shopIcon from '@iconify/icons-bi/shop';
// import Icon from '@iconify/svelte';

import icPlace from '@iconify/icons-ic/twotone-place';
import icEnhancedEncryption from '@iconify/icons-ic/twotone-enhanced-encryption';
import icInsertChartOutlined from '@iconify/icons-ic/twotone-insert-chart-outlined';
import tableReport from '@iconify/icons-icon-park-outline/table-report';
import outlineChangeCircle from '@iconify/icons-ic/outline-change-circle';

declare var require: any;

var mobile = require('is-mobile');


@Component({
  selector: 'vex-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit {

  @Input() collapsed: boolean;
  collapsedOpen$ = this.layoutService.sidenavCollapsedOpen$;

  items = this.navigationService.items;
  trackByRoute = trackByRoute;
  icRadioButtonChecked = icRadioButtonChecked;
  icRadioButtonUnchecked = icRadioButtonUnchecked;
  icPlus = icPlus;
  constructor(private navigationService: NavigationService,
    private layoutService: LayoutService,
    private dialog: MatDialog) { 
      if(!mobile()){   
        this.layoutService.expandSidenav();
      }
    }
 flagMinfo="meat";  
 system="";
  // userr:string;  
  reloadComponent(){  
      let queryString = window.location.href;
      console.log("queryString")
      console.log(queryString)

      if(queryString.indexOf('groups') !=-1){ this.system="groups";
      console.log("groups yes")

     }
     else if(queryString.indexOf('feed') !=-1){ this.system="feed";
     console.log("feed yes")
  
    }
    if( (queryString.indexOf('4005') !=-1)|| (queryString.indexOf('558') !=-1)||(queryString.indexOf('6000') !=-1)||(queryString.indexOf('700') !=-1)){   
      this.layoutService.collapseSidenav();
    }
    else if(!mobile()){
      this.layoutService.expandSidenav();

    }
  }
  ngOnInit() {
    let queryString = window.location.href;
    console.log("queryString")
    console.log(queryString)

    if(queryString.indexOf('groups') !=-1){ this.system="groups";
    console.log("groups yes")

   }
   else if(queryString.indexOf('feed') !=-1){ this.system="feed";
   console.log("feed yes")

  }

    this.items[1]['children'] = [];
    if (sessionStorage.getItem('menu')) {
      let menu = JSON.parse(sessionStorage.getItem('menu'))
      for (let item in menu) {
        if (item == '100') {
          this.flagMinfo="minfo";
          this.items[1]['children'].push({
            type: 'link',
            label: 'ניהול עדר',
            route: ['/dashboards', { menuPage: 100 }],
            icon: icAssigment,
          })
        }
        if (item == '200') {
          this.flagMinfo="minfo";
          this.items[1]['children'].push({
            type: 'link',
            label: 'ביקורים',
            route: ['/dashboards', { menuPage: 200}],
            icon: icPlace
          })
        }
        if (item == '300') {
          this.flagMinfo="minfo";
          this.items[1]['children'].push({
            type: 'link',
            label: 'וטרינריה',
            route: ['/dashboards', { menuPage: 300}],
            icon: icEnhancedEncryption
          })
        }
        if (item == '400') {
          this.flagMinfo="minfo";
          this.items[1]['children'].push({
            type: 'link',
            label: 'דוחות',
            route: ['/dashboards',{menuPage:400}],
            icon: tableReport
          },)
        }
        if (item == '1000') {
          this.flagMinfo="egglaying";

        this.items[1]['children'].push({
          type: 'link',
          label: 'תפעול',
          route: ['/dashboards', { menuPage: 1000 }],
          // route: ['/egglaying/dashboards', { menuPage: 1000 }],

          icon: icAssigment
        }) }
        if (item == '2000') {
          this.flagMinfo="egglaying";

        this.items[1]['children'].push({
          type: 'link',
          label: 'ביקורת',
          route: ['/dashboards', { menuPage: 2000 }],
          // route: ['/egglaying/dashboards', { menuPage: 1000 }],

          icon: icPlace
        }) }
        if (item == '3000') {
          this.flagMinfo="egglaying";

        this.items[1]['children'].push({
          type: 'link',
          label: 'וטרינריה',
          route: ['/dashboards', { menuPage: 3000 }],
         
          // route: ['/egglaying/dashboards', { menuPage: 1000 }],
          icon: icEnhancedEncryption
        }) }
        if (item == '4000') {
          this.flagMinfo="egglaying";

        this.items[1]['children'].push({
          type: 'link',
          label: 'דוחות',
          // route: ['/egglaying/dashboards', { menuPage: 1000 }],

          route: ['/dashboards', { menuPage: 4000 }],
          icon: tableReport
        }) 
      
      }
      if (item == '6000') {
        this.flagMinfo="egglaying";

        this.items[1]['children'].push({
          type: 'link',
          label: 'dashboard',
          route: ['/dashboards', { menuPage: 6000 }],
          // route: ['/egglaying/dashboards', { menuPage: 1000 }],
          icon: graphUpArrow
      }) 
    
    }
      if (item == '550') {
        this.flagMinfo="meat";
        this.items[1]['children'].push({
          type: 'link',
          label: 'דוחות',
          route: ['/dashboards', { menuPage: 550 }],
          icon: tableReport
        }) 
      
      }

      /////////////
      // if (item == '700') {
      //   this.flagMinfo="groups";
      //   this.items[1]['children'].push({
      //     type: 'link',
      //     label: 'דוחות',
      //     route: ['/groups/dashboards', { menuPage: 700 }],
      //     icon: tableReport
      //   }) 
      
      // }
      /////////////

      
     
       
      }  
      
      if(this.flagMinfo=="minfo"){
      this.items[1]['children'].push({
          type: 'link',
          label: 'שינוי מספר חווה / מחזור',
          route: ['/dashboards', { menuPage: 5000,tadmir:"minfo"}],
          icon: outlineChangeCircle
        })}
        else if(this.flagMinfo=="egglaying"){ 
    
          this.items[1]['children'].push({
            type: 'link',
            label: 'שינוי מספר חווה / מחזור',
            route: ['/dashboards', { menuPage: 5000,tadmir:"egglaying" }],
            icon: outlineChangeCircle
          });
        
        }
  
       
      
    }
    if(this.system=== "groups"){
      console.log("groups")
      this.items[1]['children'].push({
        type: 'link',
        label: 'מבט על',
        route: ['/dashboards', { menuPage: 700 }],
        // icon: magnifyingGlass
        icon: outlineChangeCircle

      });
      this.items[1]['children'].push({
        type: 'link',
        label: 'תערובת',
        route: ['/dashboards', { menuPage: 710 }],
        icon: outlineChangeCircle
      });
      this.items[1]['children'].push({
        type: 'link',
        label: 'ביצים',
        route: ['/dashboards', { menuPage: 720 }],
        // icon: eggsIcon
        icon: outlineChangeCircle

      });
      this.items[1]['children'].push({
        type: 'link',
        label: 'פטם',
        route: ['/dashboards', { menuPage: 730 }],
        // icon: outlineChangeCircle
        icon: outlineChangeCircle

      });
      this.items[1]['children'].push({
        type: 'link',
        label: 'בקר ',
        route: ['/dashboards', { menuPage: 740 }],
        // icon: meatIcon
        icon: outlineChangeCircle

      });
      this.items[1]['children'].push({
        type: 'link',
        label: 'מכירות עוף ',
        route: ['/dashboards', { menuPage: 750 }],
        // icon: chickenIcon
        icon: outlineChangeCircle

      });
      this.items[1]['children'].push({
        type: 'link',
        label: 'אי- קומרס ',
        route: ['/dashboards', { menuPage: 760 }],
        icon: outlineChangeCircle
      });
      this.items[1]['children'].push({
        type: 'link',
        label: 'קיצביות ',
        route: ['/dashboards', { menuPage: 770 }],
        // icon: shopIcon
        icon: outlineChangeCircle

      });
    }
    if(this.system=== "feed"){
      this.items[1]['children']=[];
      console.log("feed")
      this.items[1]['children'].push({
        type: 'link',
        label: 'הזמנות',
        route: ['/dashboards', { menuPage: 9000 }],
        // icon: magnifyingGlass
        icon: outlineChangeCircle

      });
      this.items[1]['children'].push({
        type: 'link',
        label: 'דוחות',
        route: ['/dashboards', { menuPage: 12000 }],
        // icon: magnifyingGlass
        icon: outlineChangeCircle

      });
      // this.items[1]['children'].push({
      //   type: 'link',
      //   label: 'תדמיר',
      //   route: ['/dashboards', { menuPage: 10000 }],
      //   // icon: magnifyingGlass
      //   icon: outlineChangeCircle

      // });
      this.items[1]['children'].push({
        type: 'link',
        label: 'דאשבורד',
        route: ['/dashboards', { menuPage: 11000 }],
        // icon: magnifyingGlass
        icon: outlineChangeCircle

      });
      // this.items[1]['children'].push({
      //   type: 'link',
      //   label: 'בדיקות',
      //   route: ['/dashboards', { menuPage: 13000 }],
      //   // icon: magnifyingGlass
      //   icon: outlineChangeCircle

      // });
      // this.items[1]['children'].push({
      //   type: 'link',
      //   label: 'דרישות מרווח',
      //   route: ['/dashboards', { menuPage: 9001 }],
      //   // icon: eggsIcon
      //   icon: outlineChangeCircle

      // });
    }

  }

  onMouseEnter() {
    this.layoutService.collapseOpenSidenav();
  }

  onMouseLeave() {
    this.layoutService.collapseCloseSidenav();
  }

  toggleCollapse() {
    console.log(this.collapsed);
    
    this.collapsed ? this.layoutService.expandSidenav() : this.layoutService.collapseSidenav();
  }

  openSearch() {
    this.layoutService.openSearch();
  }
}
