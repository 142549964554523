import { Component, ViewChild, TemplateRef, Renderer2 } from '@angular/core';
import { DialogBase } from '../index';
import { DialogService } from '../dialog.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'dialog-cmp',
    templateUrl: 'dialog-mat.component.html',

})
export class DialogMatComponent extends DialogBase {
    dialogRef;

    @ViewChild('dialogContant', { static: false }) dialogContent: TemplateRef<any>;

    constructor(dialogService: DialogService, renderer: Renderer2, public dialog: MatDialog) {
        super(dialogService, renderer);
        // console.log('constructor mat');
    }

    triggerShow() {
        // console.log('triggerShow');
        const dialogRef = this.dialog.open(this.dialogContent, {
            width: '500px',
        });

        dialogRef.afterClosed().subscribe(result => {
            // console.log('The dialog was closed');
        });
    }

    triggerHide() {
        // console.log('triggerHide');
        this.dialogRef.close();
    }
}
