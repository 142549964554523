import { DateTime } from 'luxon';
import { Icon } from '@visurel/iconify-angular';



// export icCheckCircle from '@iconify/icons-ic/twotone-check-circle';
// export icWarning from '@iconify/icons-ic/twotone-warning';
// export icAccountCircle from '@iconify/icons-ic/twotone-account-circle';



// export interface NotificationInterface {
//   id?: number;
//   label: string;
//   icon?: Icon;
//   datetime?: DateTime;
//   read?: boolean;
//   colorClass: ColorType,
// }


export class Notification {

  private static id = 0;
  public id = ++Notification.id;
  public label: string;
  public icon: Icon;
  public colorClass: ColorType;
  public datetime: DateTime;
  public seen?: boolean;
  public read?: boolean;

  constructor(obj: Object = null) {

    if (typeof obj != 'object') {
      throw new TypeError('Invalid object');
    }
    Object.assign(this, obj);
  }
}


// export enum IconType {

//   success = 'icCheckCircle',
//   icFeedback,
//   icError
// }

export enum ColorType {
  success = 'text-green-500',
  warning = 'text-orange-500',
  error = 'text-red-500'
}
