import { Component } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { SnackbarService } from './snackbar.service';
import { SnackbarRequest } from './snackbar.model';

@Component({
	selector: 'snackbar-cmp',
	templateUrl: 'snackbar.component.html',
	styleUrls: ['snackbar.component.css'],
	animations: [
		trigger('fadeInOut', [
			state('in', style({ opacity: 1 })),
			transition('void => *', [
				style({ opacity: 0 }),
				animate(500)
			]),
			transition('* => out', [
				style({ opacity: 1 }),
				animate(1500, style({ opacity: 0 }))
			])
		])
	]
})
export class SnackbarComponent {
	pendingRequests: SnackbarRequest[] = [];
	displayRequests: SnackbarRequest[] = [];
	oneByOne = true;//true: no pushing to display until fadein is done
	fadeInPend = false;
	checkingEmpty = false;

	constructor(snackbarService: SnackbarService) {
		snackbarService.snackbarCalled$.subscribe((request: SnackbarRequest) => {
			this.pendingRequests.push(request);
			this.pushDisplay();
		});
	}

	private pushDisplay() {
		if (this.fadeInPend || this.checkingEmpty || !this.pendingRequests.length) return;
		this.fadeInPend = (this.oneByOne && true);
		let next = this.pendingRequests.shift();
		this.displayRequests.push(next);
		next.displayState = 'in';//trigger fade in
	}

	 fadeDone(e, request: SnackbarRequest) {
		switch (e.toState) {
			case 'in':
				request.setTimeout();//trigger fade out on timeout
				if (this.oneByOne) {
					this.fadeInPend = false;
					this.pushDisplay();
				}
				break;
			case 'out':
				this.dismiss(request);
				break;
		}
	}

	 dismiss(request: SnackbarRequest) {
		request.displayState = 'dismissed';
		this.checkEmpty();
	}

	private checkEmpty() {
		setTimeout(() => {
			this.checkingEmpty = true;
			if (this.displayRequests.every((request: SnackbarRequest) => request.displayState == 'dismissed')) {
				this.displayRequests = [];
			}
			this.checkingEmpty = false;
		}, 0);
	}

	onClicked(req) {
		req.clearTimeout();
		req.notiObj.read = true;
	}
}
