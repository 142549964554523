import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, OnDestroy } from '@angular/core';
import { MenuItem } from '../interfaces/menu-item.interface';
import { trackById } from '../../../utils/track-by';
import icPerson from '@iconify/icons-ic/twotone-person';
import icSettings from '@iconify/icons-ic/twotone-settings';
import icAccountCircle from '@iconify/icons-ic/twotone-account-circle';
import icMoveToInbox from '@iconify/icons-ic/twotone-move-to-inbox';
import icListAlt from '@iconify/icons-ic/twotone-list-alt';
import icTableChart from '@iconify/icons-ic/twotone-table-chart';
import icCheckCircle from '@iconify/icons-ic/twotone-check-circle';
import icAccessTime from '@iconify/icons-ic/twotone-access-time';
import icDoNotDisturb from '@iconify/icons-ic/twotone-do-not-disturb';
import icOfflineBolt from '@iconify/icons-ic/twotone-offline-bolt';
import icChevronRight from '@iconify/icons-ic/twotone-chevron-right';
import icChevronLeft from '@iconify/icons-ic/twotone-chevron-left';
import icArrowDropDown from '@iconify/icons-ic/twotone-arrow-drop-down';
import icBusiness from '@iconify/icons-ic/twotone-business';
import icVerifiedUser from '@iconify/icons-ic/twotone-verified-user';
import icLock from '@iconify/icons-ic/twotone-lock';
import icNotificationsOff from '@iconify/icons-ic/twotone-notifications-off';
import { Icon } from '@visurel/iconify-angular';
import { PopoverRef } from '../../popover/popover-ref';
import { LoginService } from '../../../../app/services/login.service';
import { Router } from '@angular/router';




export interface OnlineStatus {
  id: 'online' | 'away' | 'dnd' | 'offline';
  label: string;
  icon: Icon;
  colorClass: string;
}

@Component({
  selector: 'vex-toolbar-user-dropdown',
  templateUrl: './toolbar-user-dropdown.component.html',
  styleUrls: ['./toolbar-user-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToolbarUserDropdownComponent implements OnInit {

  userName: string;

  trackById = trackById;
  icPerson = icPerson;
  icSettings = icSettings;
  icChevronRight = icChevronRight;
  icChevronLeft = icChevronLeft;
  icArrowDropDown = icArrowDropDown;
  icBusiness = icBusiness;
  icVerifiedUser = icVerifiedUser;
  icLock = icLock;
  icNotificationsOff = icNotificationsOff;

  constructor(private cd: ChangeDetectorRef,
    private popoverRef: PopoverRef<ToolbarUserDropdownComponent>,
    private _loginService: LoginService,
    private router: Router) { }

  userr:string;
  minfoOrEgglaying:string;
  ngOnInit() {
    this.userName = this._loginService.userName;



    
     this.userr=window.location.href;
     if( this.userr.indexOf('egglaying') >=0){
      this.minfoOrEgglaying="egglaying";
     }
        else if( this.userr.indexOf('meat') >=0) {
      this.minfoOrEgglaying="meat";
     }
     else if( this.userr.indexOf('minfo') >=0) {
      this.minfoOrEgglaying="minfo";
     }
      else if( this.userr.indexOf('groups') >=0) {
      this.minfoOrEgglaying="groups";
     }
     else if( this.userr.indexOf('feed') >=0) {
      this.minfoOrEgglaying="feed";
     }
 

  }

  // setStatus(status: OnlineStatus) {
  //   this.activeStatus = status;
  //   this.cd.markForCheck();
  // }

  close() {
    
    this.popoverRef.close();
    this._loginService.logout().subscribe(res => {
      console.log(res);
      if (res) {
        // localStorage.clear();
        sessionStorage.clear();
      }
    });
    // this.router.navigate(['/minfo/login'])
  }


}
