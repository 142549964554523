import { NgModule, ModuleWithProviders } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { DialogMatComponent } from './dialog-mat.component';

@NgModule({
    imports: [CommonModule, FormsModule, MatDialogModule],
    declarations: [DialogMatComponent],
    exports: [DialogMatComponent],
})
export class DialogMatModule { }
