import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { of } from 'rxjs';



import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { QBResponsePacket } from '../shared/qb-response-packet.class';
import { QBClientServiceClass } from './qb-client.service';
import { GlobalService, NotificationService } from './global.service'
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class LoginService extends QBClientServiceClass {

  private url = 'auth';


  private isloggedIn: boolean;
  userName: string;


  constructor(protected http: HttpClient, protected glob: GlobalService, protected notificationService: NotificationService, protected router: Router) {
    super(http, glob, notificationService);
    this.isloggedIn = false;
  }
  // login(){
  //   return this.http
  // }
  // login(user: any) {
  //   console.log(user);
  //   return this.http.post<any>('http://localhost:4200/srv/auth/login', user).pipe(map(res => console.log(res)));
  // }

  login(user: any): Observable<any> {
    return this.httpPost({ url: this.url + '/login', body: user || {} }).pipe(map((response: QBResponsePacket) => {
      if (response.data) {
        this.isloggedIn = true;
        this.userName = response.data.userName;
        return response.data;
      }
    }));

    
    // console.log(user);
    // if (user) {
    //   this.isloggedIn = true;
    //   this.userName = user.username;
    //   return of(this.isloggedIn);
    // }
    // return of(true);
  }
  hatlogin(user: any): Observable<any> {
    return this.httpPost({ url: this.url + '/hatlogin', body: user || {} }).pipe(map((response: QBResponsePacket) => {
      if (response.data) {
        this.isloggedIn = true;
        this.userName = response.data.userName;
        return response.data;
      }
    }));


    
    // console.log(user);
    // if (user) {
    //   this.isloggedIn = true;
    //   this.userName = user.username;
    //   return of(this.isloggedIn);
    // }
    // return of(true);
  }
  feedlogin(user: any): Observable<any> {
    return this.httpPost({ url: this.url + '/feedlogin', body: user || {} }).pipe(map((response: QBResponsePacket) => {
      if (response.data) {
        this.isloggedIn = true;
        this.userName = response.data.userName;
        return response.data;
      }
    }));}
  meatlogin(user: any): Observable<any> {
    return this.httpPost({ url: this.url + '/meatlogin', body: user || {} }).pipe(map((response: QBResponsePacket) => {
      if (response.data) {
        this.isloggedIn = true;
        this.userName = response.data.userName;
        return response.data;
      }
    }));}


  chckAuth() {
    // console.log(this.isloggedIn);
    return of(this.isloggedIn);
    // return this.isloggedIn;

  }

  logout(params?: {} & { [prop: string]: any }): Observable<any> {
    this.isloggedIn = false;
    return this.httpPost({ url: this.url + '/logout', body: params || {} }).pipe(map((response: QBResponsePacket) => response||''));
  }

  // logoutServer(params?: {} & { [prop: string]: any }): Observable<QBResponsePacket> {
  //   return this.httpPost({ url: this.url + '/logout', body: params || {} });
  // }

}


