<div class="dropdown">
  <div class="dropdown-header" style="display: flex;
  flex-direction: row;     justify-content: space-around;     flex-wrap: wrap;" fxLayoutAlign="space-between center">
    <div class="dropdown-app">
      <div class="dropdown-heading">התראות</div>
      <div class="dropdown-subheading"> יש לך - {{ countUnreadNoti() }} - התראות חדשות.</div>
    </div>

    <!-- <button [matMenuTriggerFor]="settingsMenu" mat-icon-button type="button">
      <mat-icon [icIcon]="icSettings" class="notifications-header-icon"></mat-icon>
    </button> -->
  </div>

  <div class="dropdown-content">
    <div *ngFor="let notification of notifications.reverse(); trackBy: trackById" [class.read]="notification.read"
      class="notification" style="display: flex;
      flex-direction: row;     justify-content: space-around;   
        flex-wrap: wrap; align-items: flex-start; align-content: center">
      <ic-icon style="width: 10%;" [icon]="notification.icon" [ngClass]="notification.colorClass" class="notification-icon" fxFlex="none"
        size="24px"></ic-icon>
      <div fxFlex="auto"  style="width: 70%;">
        <div class="notification-label">{{ notification.label }}</div>
        <div class="notification-description">{{ notification.datetime | relativeDateTime }}</div>
      </div>
      <!-- <ic-icon (click)="close(notification)" [icon]="icClose" [ngClass]="notification.colorClass"
        class="notification-icon-close" fxFlex="none" size="24px"></ic-icon> -->
      <!-- <mat-icon [icIcon]="icClose" class="notification-icon-clos"></mat-icon> -->

      <!-- <mat-icon [icIcon]="icChevronRight" class="notification-chevron" fxFlex="none"></mat-icon> -->
      <!-- <button (click)="close(notification)" class="button" type="button">&times;</button> -->
      <!-- <mat-icon [icIcon]="icChevronLeft" class="notification-chevron" fxFlex="none"></mat-icon> -->
      <button (click)="close(notification)" style="width: 10%;">
        <mat-icon [icIcon]="icClose"></mat-icon>
      </button>
    </div>
  </div>

  <div class="dropdown-footer" style="display: flex;
  flex-direction: row;     justify-content: space-around;     flex-wrap: wrap;" fxLayoutAlign="center center">
    <button class="w-full" color="primary" mat-button type="button" (click)="clearAll()">נקה הכול</button>
  </div>
</div>

<mat-menu #settingsMenu="matMenu" xPosition="before" yPosition="below">
  <button mat-menu-item>
    <mat-icon [icIcon]="icClearAll"></mat-icon>
    <span>Mark all as read</span>
  </button>

  <button mat-menu-item>
    <mat-icon [icIcon]="icNotificationsOff"></mat-icon>
    <span>Disable Notifications</span>
  </button>
</mat-menu>