<!-- <div id="snackbars-container">
  <ng-container *ngFor="let req of displayRequests">
    <div *ngIf="req.displayState!='dismissed'" class="alert alert-{{req.type}} {{req.displayState}}" role="alert"
      [class.timing]="req.timerState=='set'" [@fadeInOut]="req.displayState" (@fadeInOut.done)="fadeDone($event, req)"
      (click)="onClicked(req)">

      <span [innerHTML]="req.msg"></span>
      <button *ngIf="req.dismissible" type="button" class="close mat-simple-snackbar-action" (click)="dismiss(req)"
        aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </ng-container>
</div> -->

<!-- <button *ngIf="req.dismissible" type="button" class="close" (click)="dismiss(req)" aria-label="Close">
  <span aria-hidden="true">&times;</span>
</button> -->


<div id="snackbars-container">
  <ng-container *ngFor="let req of displayRequests">
    <div *ngIf="req.displayState!='dismissed'" class="alert alert-{{req.type}} {{req.displayState}}" role="alert"
      [class.timing]="req.timerState=='set'" [@fadeInOut]="req.displayState" (@fadeInOut.done)="fadeDone($event, req)"
      (click)="onClicked(req)">

      <!-- <span [innerHTML]="req.msg"></span> -->
      <!-- <button *ngIf="req.dismissible" type="button" class="close mat-button mat-button-base" (click)="dismiss(req)"
        aria-label="Close">
        <span class="mat-button-wrapper" (click)="dismiss(req)">סגור</span>
      </button> -->


      



      <div class="mat-simple-snackbar ng-star-inserted">
        <span [innerHTML]="req.msg"></span>

        <div class="mat-simple-snackbar-action ng-star-inserted">
          <button mat-button="" (click)="dismiss(req)" class="mat-button mat-button-base"><span
              class="mat-button-wrapper"> סגור</span>
            <div class="mat-button-ripple mat-ripple" matripple="" ng-reflect-centered="false"
              ng-reflect-disabled="false" ng-reflect-trigger="[object HTMLButtonElement]"></div>
            <div class="mat-button-focus-overlay"></div>
          </button>
        </div>
      </div>

      <!-- <button *ngIf="req.dismissible" mat-button="" class="mat-button mat-button-base">
        <span class="mat-button-wrapper"
          (click)="dismiss(req)">סגור</span>
        <div class="mat-button-ripple mat-ripple" matripple="" ng-reflect-centered="false" ng-reflect-disabled="false"
          ng-reflect-trigger="[object HTMLButtonElement]"></div>
        <div class="mat-button-focus-overlay"></div>
      </button> -->

    </div>
  </ng-container>
</div>