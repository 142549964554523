import { Component, ElementRef, HostBinding, Inject, Input, OnInit } from '@angular/core';
import { LayoutService } from '../../services/layout.service';
import icBookmarks from '@iconify/icons-ic/twotone-bookmarks';
import emojioneUS from '@iconify/icons-emojione/flag-for-flag-united-states';
import emojioneDE from '@iconify/icons-emojione/flag-for-flag-germany';
import icMenu from '@iconify/icons-ic/twotone-menu';
import { ConfigService } from '../../services/config.service';
import { map } from 'rxjs/operators';
import icPersonAdd from '@iconify/icons-ic/twotone-person-add';
import icAssignmentTurnedIn from '@iconify/icons-ic/twotone-assignment-turned-in';
import icBallot from '@iconify/icons-ic/twotone-ballot';
import icDescription from '@iconify/icons-ic/twotone-description';
import icAssignment from '@iconify/icons-ic/twotone-assignment';
import icReceipt from '@iconify/icons-ic/twotone-receipt';
import icDoneAll from '@iconify/icons-ic/twotone-done-all';
import { NavigationService } from '../../services/navigation.service';
import icArrowDropDown from '@iconify/icons-ic/twotone-arrow-drop-down';
import { PopoverService } from '../../components/popover/popover.service';
import { MegaMenuComponent } from '../../components/mega-menu/mega-menu.component';
import icSearch from '@iconify/icons-ic/twotone-search';
import { NotificationService } from '../../../app/services/notification.service';
import { Notification } from '../../../@vex/components/toolbar-notifications/interfaces/notification.interface';
import { DateTime } from 'luxon';
import icCheckCircle from '@iconify/icons-ic/twotone-check-circle';
import icPlus from '@iconify/icons-ic/twotone-plus';
import { MatDialog } from '@angular/material/dialog';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';

import { Observable } from 'rxjs';
// import { DashboardService } from '../dashboard-analytics/dashboard-analytics.service';
import { DashboardService } from '../../../app/pages/dashboards/dashboard-analytics/dashboard-analytics.service';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import icClose from '@iconify/icons-ic/twotone-close';
import { PwaService } from './pwa.service';
import icCloudDownload from '@iconify/icons-ic/twotone-cloud-download';


var mobile = require('is-mobile');
@Component({
  selector: 'vex-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit {

  @Input() mobileQuery: boolean;
@Input() farmSave :string|number;
@Input() cycleSave:string|number;
icCloudDownload=icCloudDownload;
 userId:string|number;
 mob=mobile();
 icClose = icClose;

  @Input()
  @HostBinding('class.shadow-b')
  hasShadow: boolean;

  navigationItems = this.navigationService.items;

  isHorizontalLayout$ = this.configService.config$.pipe(map(config => config.layout === 'horizontal'));
  isVerticalLayout$ = this.configService.config$.pipe(map(config => config.layout === 'vertical'));
  isNavbarInToolbar$ = this.configService.config$.pipe(map(config => config.navbar.position === 'in-toolbar'));
  isNavbarBelowToolbar$ = this.configService.config$.pipe(map(config => config.navbar.position === 'below-toolbar'));

  icSearch = icSearch;
  icBookmarks = icBookmarks;
  emojioneUS = emojioneUS;
  emojioneDE = emojioneDE;
  icMenu = icMenu;
  icPersonAdd = icPersonAdd;
  icAssignmentTurnedIn = icAssignmentTurnedIn;
  icBallot = icBallot;
  icDescription = icDescription;
  icAssignment = icAssignment;
  icReceipt = icReceipt;
  icDoneAll = icDoneAll;
  icArrowDropDown = icArrowDropDown;
  icPlus = icPlus;
  containerfarmOption$:Observable<any[]>;
  cycleOption$:Observable<any[]>;
  numUrl:number;
  userr:string;
numIndex:number;
mysfarm :string;
myscycle:string;
mysfarmStr :string;
myscycleStr:string;
menuPage:string;
  constructor(private layoutService: LayoutService,
    private configService: ConfigService,
    private navigationService: NavigationService,
    private popoverService: PopoverService,
    protected _notafication: NotificationService,
    private route: ActivatedRoute,
    protected ds: DashboardService,
    private dialog: MatDialog,
    public pwa: PwaService,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: { mobileType: 'ios' | 'android', promptEvent?: any },
    private bottomSheetRef: MatBottomSheetRef<ToolbarComponent>


  ) {     this.route.paramMap.subscribe(params => {
    this.menuPage = params.get('menuPage');

    this.ngOnInit();

});}
  ngOnInit() {
   this.numUrl=window.location.href.indexOf('login');
    this.userId = localStorage.getItem('userId') || '';
    this.userr=window.location.href;
    if(this.userr.indexOf('egglaying')>=0){
      this.mysfarm =localStorage.getItem('farmEgglaying');
      this.myscycle=localStorage.getItem('cycleEgglaying');
      if (this.userId) this.containerfarmOption$ = this.ds.getHatFarms({ userId: this.userId });
      if(this.containerfarmOption$!=null)
      this.cycleOption$ = this.ds.getHatCycle({ userId: this.userId, farmId: this.mysfarm});
    }
    else if(this.userr.indexOf('minfo')>=0&&this.userr.indexOf('feed')<0){
      this.mysfarm =localStorage.getItem('farmMinfo');
      this.myscycle=localStorage.getItem('cycleMinfo');
      if (this.userId) this.containerfarmOption$ = this.ds.getFarms({ userId: this.userId });
          if(this.containerfarmOption$!=null)
          this.cycleOption$ = this.ds.getCycle({ userId: this.userId, farmId: this.mysfarm});

       
    }
    if(this.userr.indexOf('meat')==-1){
      if(this.containerfarmOption$!=undefined){
    this.containerfarmOption$.forEach((key)=>{
              for(let i=0;i<key.length;i++){
                  if(key[i]['id']== this.mysfarm){
                    this.mysfarmStr=key[i]['name'];
                    console.log( this.mysfarmStr);
                  }
              }
            })}
  if(this.cycleOption$!=undefined){
  this.cycleOption$.forEach((key)=>{
    for(let i=0;i<key.length;i++){
        if(key[i]['id']== this.myscycle){
          this.myscycleStr=key[i]['name'];
        }
    }
  });}  
    }
    else if(this.userr.indexOf('feed')>=0){
      this.numUrl=10;
    }

 }
 

 installPwa(): void {
   console.log( this.data);
  this.data.promptEvent.prompt();
  this.close();

}
public close() {
  this.bottomSheetRef.dismiss();
}

 tit():string{
  if(this.userr.indexOf('559')>=0){
    return localStorage.getItem('titleDash');
  }
  else{
    return '';
  }
}
  farmId:number| string;
  cycleId:number| string;
 
  pushNote() {
    this._notafication.success(1, 'yesss!!!', icCheckCircle);
    // this._notafication.push();
  }

  openQuickpanel() {
    this.layoutService.openQuickpanel();
  }

  openSidenav() {
    this.layoutService.openSidenav();
  }

  // openMegaMenu(origin: ElementRef | HTMLElement) {
  //   this.popoverService.open({
  //     content: MegaMenuComponent,
  //     origin,
  //     position: [
  //       {
  //         originX: 'start',
  //         originY: 'bottom',
  //         overlayX: 'start',
  //         overlayY: 'top'
  //       },
  //       {
  //         originX: 'end',
  //         originY: 'bottom',
  //         overlayX: 'end',
  //         overlayY: 'top',
  //       },
  //     ]
  //   });
  // }.

  // openContainer(container: Container) {
  //   // console.log(container);
  //   this.dialog.open(ContainerTrackingEditComponent, {
  //     data: container || null,
  //     width: '50%',
  //   });
  // }
  openSearch() {
    this.layoutService.openSearch();
  }
}
