import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
// import { DialogDxPopupModule as DialogModule } from './dialog/dx-popup/dialog-dx-popup.module'; DialogMatModule
import { DialogMatModule as DialogModule } from './dialog/dialog-mat/dialog-mat.module';
import { SnackbarModule } from './snackbar/snackbar.module';
/**
* Do not specify providers for modules that might be imported by a lazy loaded module.
*/

@NgModule({
    imports: [CommonModule, DialogModule, SnackbarModule],
    declarations: [],
    exports: [CommonModule, FormsModule, DialogModule, SnackbarModule],
})
export class SharedModule { }
