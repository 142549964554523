import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest } from '@angular/common/http';
import { QBClientService, QBClientServiceClass } from '../qb-client.service';
import { GlobalService, GlobalVariables, QBErrorPostObj, QBBackendReportableError, NotificationService, ColorType } from '../global.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DateTime } from 'luxon';
import icWarning from '@iconify/icons-ic/twotone-warning';

@Injectable()
export class ErrorReportService extends QBClientServiceClass implements QBClientService {
  private lastFrontendError = '';
  url: string;

  constructor(protected http: HttpClient, protected glob: GlobalService,
    protected notificationService: NotificationService,
    protected sBar: MatSnackBar) {
    super(http, glob, notificationService);
    this.url = this.errUrl;
  }

  // get globals(): GlobalVariables { return this.glob.getVars(); };

  reportBackendError(requstData: HttpRequest<any>, error: QBBackendReportableError) {
    // console.log('reportBackendError', requstData, error);

    if (this.debug) console.log('ErrorReportService.reportBackendError:\n  requstData:\n  ', requstData, '\n  error\n  ', error);
    let err = new QBErrorPostObj();
    err.errorObject = error;
    err.message = error['message'];
    err.request = requstData;
    err.source = error.source;
    //errorObject['source'] = 'frontend';
    this.httpPostError(err);
  }

  checkLastFrontendError(err) {
    // console.log('checkLastFrontendError');
    let prev = this.lastFrontendError;
    this.lastFrontendError = err.toString();
    setTimeout(() => this.lastFrontendError = '', 2000);
    // console.log('prev', prev);
    // console.log('lastFrontendError', this.lastFrontendError);
    // console.log('this.lastFrontendError == prev', this.lastFrontendError == prev);
    let ret = (prev === this.lastFrontendError);
    if (this.errDebug) console.log('ErrorReportService.checkLastFrontendError:\n  err:\n  ', err, '\n  ret:\n  ', ret);
    return ret;
  }

  reportFrontendError(errorObject: Object) {
    if (this.errDebug) console.log('ErrorReportService.reportFrontendError:\n  errorObject:\n  ', errorObject);
    // let showAlert = false;
    let showAlert = true;
    if (errorObject['exception']) {
      let e = errorObject['exception'];
      delete errorObject['exception'];
      let error = {};
      error['message'] = (e.message) ? e.message : '';
      if (this.checkLastFrontendError(error['message'])) return;
      error['name'] = (e.name) ? e.name : '';
      error['fileName'] = (e.fileName) ? e.fileName : '';
      error['lineNumber'] = (e.lineNumber) ? e.lineNumber : '';
      error['columnNumber'] = (e.columnNumber) ? e.columnNumber : '';
      error['stack'] = (e.stack) ? e.stack : '';
      errorObject['errorObject'] = error;

      //if(error['message'] == "Unexpected end of JSON input") return;
      if (error['message'].indexOf('ExpressionChangedAfterItHasBeenCheckedError') > -1) return;
      // if (error['message'].indexOf('Cannot read property \'length\' of undefined') > -1) return;
    } else {
      if (this.checkLastFrontendError(errorObject['message'])) return;
    }
    let time = Date.now();
    if (showAlert) {
      let msg = `חלה שגיאה בממשק המשתמש. נא להפנות לתמיכה הטכנית את המזהה הבא: <b>${time}</b>
                נודה לכם אם תוכלו להעתיק את הטקסט או לצלם את המסך.\n
                יתכן שהבעיה היא נקודתית בדפדפן (דפדפן מיושן או גרסא לא עדכנית וכו').
                מומלץ לבדוק אם הבעיה חוזרת על עצמה גם בדפדפן אחר או לסגור את הדפדפן (כל הלשוניות) ולפתוח מחדש.`

      this.glob.notify({
        label: ` חלה שגיאה בממשק משתמש , במידה והבעיה ממשיכה מומלץ לסגור את הדפדפן שגיאה מס: ${time} `,
        colorClass: ColorType.warning,
        icon: icWarning,
        showSnackbar: true
      });
    }
    errorObject['clientTime'] = time;
    errorObject['userAgent'] = navigator.userAgent;
    errorObject['source'] = 'frontend';
    this.httpPostError(new QBErrorPostObj(errorObject));
  }
}
