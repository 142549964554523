<div class="footer w-full p-gutter text-center" fxLayout="row">
  <div fxFlex="auto" fxLayout="row" style="align-items: flex-start; align-content: center" vexContainer>
    <!-- <a class="action"
       color="primary"
       fxFlex="none"
       href="//themeforest.net/item/vex-angular-8-material-design-admin-template/24472891"
       id="get-vex"
       mat-raised-button>
      <ic-icon [icon]="icShoppingBasket" class="ltr:mr-2 rtl:ml-2" inline="true" size="18px"></ic-icon>
      <span>Get Vex (Angular 8+)</span>
    </a> -->
    <div class="text-center" fxHide fxShow.gt-sm>
      <a href="https://www.q-bytes.com" target="_blank"> 2021 || Quality Bytes</a>
    </div>
  </div>
</div>