import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
//import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SnackbarComponent } from './index';

@NgModule({
    imports: [CommonModule/*,BrowserAnimationsModule*/],
    declarations: [SnackbarComponent],
    exports: [SnackbarComponent],
})
export class SnackbarModule { }
