import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanLoad, Route } from '@angular/router';
import { Observable, from } from 'rxjs';
import { LoginService } from '../services/login.service';
import { Role } from '../pages/premission/role';
import { AuthService } from './auth.service'

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {

    constructor(
        private _router: Router,
        private _loginService: LoginService,
        private authService: AuthService
        // protected authService: AuthService
    ) { }
    userr=window.location.href;

    canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        if (!this.authService.isAuthorized()) {
            if( this.userr.indexOf('egglaying') >=0){
                this._router.navigate(['login']);
                // this._router.navigate(['egglaying/login']);
            } 
            else if(this.userr.indexOf('meat') >=0){
                this._router.navigate(['meat/login']);
            }
            else if(this.userr.indexOf('minfo') >=0){
                this._router.navigate(['minfo/login']);
            }
             else if(this.userr.indexOf('groups') >=0){
                this._router.navigate(['groups/login']);
            }
            else if(this.userr.indexOf('feed') >=0){
                this._router.navigate(['feed/login']);
            }
         
            return false;
        }

        const roles = route.data.roles as Role[];
        if (roles && !roles.some(r => this.authService.hasRole(r))) {
            this._router.navigate(['error-404']);
            return false;
        }

        return true;
    }

    canLoad(route: Route): Observable<boolean> | Promise<boolean> | boolean {
        if (!this.authService.isAuthorized()) {
            return false;
        }

        const roles = route.data && route.data.roles as Role[];
        if (roles && !roles.some(r => this.authService.hasRole(r))) {
            return false;
        }

        return true;
    }



}