import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit, OnDestroy, ViewChild, AfterViewInit } from '@angular/core';
import { PopoverService } from '../popover/popover.service';
import { ToolbarNotificationsDropdownComponent } from './toolbar-notifications-dropdown/toolbar-notifications-dropdown.component';
import icNotificationsActive from '@iconify/icons-ic/twotone-notifications-active';
import { DateTime } from 'luxon';
import { ColorType, Notification } from '../toolbar-notifications/interfaces/notification.interface';
import { NotificationService } from '../../../app/services/notification.service';
import { Observable, Subscription } from 'rxjs';
import { GlobalService } from 'src/app/services/global.service';
import { QBResponsePacket } from 'src/app/shared/qb-response-packet.class';
import { map } from 'rxjs/operators';

@Component({
  selector: 'vex-toolbar-notifications',
  templateUrl: './toolbar-notifications.component.html',
  styleUrls: ['./toolbar-notifications.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToolbarNotificationsComponent implements OnInit, AfterViewInit {


  @ViewChild('originRef', { static: true, read: ElementRef }) originRef: ElementRef;
  dropdownOpen: boolean;
  icNotificationsActive = icNotificationsActive;
  subscriptions: { [name: string]: Subscription } = {};
  unreadCount = 0;

  constructor(private popover: PopoverService,
    private cd: ChangeDetectorRef,
    private _notificationService: NotificationService,

    protected glob: GlobalService){///////////snir

    _notificationService.notificationCalled$.subscribe((request: any) => {
      // this.pendingRequests.push(request);
      // this.pushDisplay();
      this.cd.markForCheck();
    });
  }

  ngOnInit() {
    this._notificationService.notifications = [];

  }

  ngAfterViewInit() {

  }

  showPopover() {


    this.cd.markForCheck();
    this.dropdownOpen = true;


    const popoverRef = this.popover.open({
      content: ToolbarNotificationsDropdownComponent,
      origin: this.originRef,
      offsetY: 12,
      position: [
        {
          originX: 'center',
          originY: 'top',
          overlayX: 'center',
          overlayY: 'bottom'
        },
        {
          originX: 'end',
          originY: 'bottom',
          overlayX: 'end',
          overlayY: 'top',
        },
      ]
    });

    popoverRef.afterClosed$.subscribe(() => {
      this.dropdownOpen = false;
      this.cd.markForCheck();
    });

    // set all notifications as seen.
    this._notificationService.notifications.forEach(item => item.seen = true);

  }

  countUnseenNoti() {
    const length = this._notificationService.notifications.filter(item => !item.seen).length;
    return length ? length : null;
  }



  /////////////////snir///////////
  snirsFunc(){
    this.glob.notify({
      label: `שניר הצלחה !`,
      colorClass: ColorType.success,
      showSnackbar: true
    });
  }

}
