import { Component, OnInit, OnDestroy, Input, OnChanges, AfterViewInit } from '@angular/core';
import { Notification } from '../interfaces/notification.interface';
import { DateTime } from 'luxon';
import { trackById } from '../../../utils/track-by';
import icSettings from '@iconify/icons-ic/twotone-settings';
import icClose from '@iconify/icons-ic/twotone-close';
import icChevronRight from '@iconify/icons-ic/twotone-chevron-right';
import icChevronLeft from '@iconify/icons-ic/twotone-chevron-left';
import icNotificationsOff from '@iconify/icons-ic/twotone-notifications-off';
import icClearAll from '@iconify/icons-ic/twotone-clear-all';
import icShoppingBasket from '@iconify/icons-ic/twotone-shopping-basket';
import icAccountCircle from '@iconify/icons-ic/twotone-account-circle';
import icInsertChart from '@iconify/icons-ic/twotone-insert-chart';
import icCheckCircle from '@iconify/icons-ic/twotone-check-circle';
import icDescription from '@iconify/icons-ic/twotone-description';
import icFeedback from '@iconify/icons-ic/twotone-feedback';
import icVerifiedUser from '@iconify/icons-ic/twotone-verified-user';
import icFileCopy from '@iconify/icons-ic/twotone-file-copy';
import { Subscription } from 'rxjs';
import { NotificationService } from '../../../../app/services/notification.service';

@Component({
  selector: 'vex-toolbar-notifications-dropdown',
  templateUrl: './toolbar-notifications-dropdown.component.html',
  styleUrls: ['./toolbar-notifications-dropdown.component.scss']
})
export class ToolbarNotificationsDropdownComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {

  notifications: Notification[] = [
    // {
    //   id: '1',
    //   label: 'New Order Received',
    //   icon: icShoppingBasket,
    //   colorClass: 'text-primary-500',
    //   datetime: DateTime.local().minus({ hour: 1 })
    // },
    // {
    //   id: '2',
    //   label: 'New customer has registered',
    //   icon: icAccountCircle,
    //   colorClass: 'text-orange-500',
    //   datetime: DateTime.local().minus({ hour: 2 })
    // },
    // {
    //   id: '3',
    //   label: 'Campaign statistics are available',
    //   icon: icInsertChart,
    //   colorClass: 'text-purple-500',
    //   datetime: DateTime.local().minus({ hour: 5 })
    // },
    // {
    //   id: '4',
    //   label: 'Project has been approved',
    //   icon: icCheckCircle,
    //   colorClass: 'text-green-500',
    //   datetime: DateTime.local().minus({ hour: 9 })
    // },
    // {
    //   id: '5',
    //   label: 'Client reports are available',
    //   icon: icDescription,
    //   colorClass: 'text-primary-500',
    //   datetime: DateTime.local().minus({ hour: 30 })
    // },
    // {
    //   id: '6',
    //   label: 'New review received',
    //   icon: icFeedback,
    //   colorClass: 'text-orange-500',
    //   datetime: DateTime.local().minus({ hour: 40 }),
    //   read: true
    // },
    // {
    //   id: '7',
    //   label: '22 verified registrations',
    //   icon: icVerifiedUser,
    //   colorClass: 'text-green-500',
    //   datetime: DateTime.local().minus({ hour: 60 })
    // },
    // {
    //   id: '8',
    //   label: 'New files available',
    //   icon: icFileCopy,
    //   colorClass: 'text-amber-500',
    //   datetime: DateTime.local().minus({ hour: 90 })
    // }
  ];
  private _subscription: Subscription;

  icSettings = icSettings;
  icClose = icClose;
  icChevronRight = icChevronRight;
  icChevronLeft = icChevronLeft;
  icClearAll = icClearAll;
  icNotificationsOff = icNotificationsOff;
  trackById = trackById;

  constructor(
    private _notificationService: NotificationService,
  ) {


  }

  ngOnChanges() {

  }

  ngOnInit() {
    this.notifications = this._notificationService.notifications
  }


  ngAfterViewInit() {
  }

  countUnreadNoti() {
    return this._notificationService.notifications.filter(item => !item.read).length;
  }


  close(notification: Notification) {
    this.notifications = this.notifications.filter(note => note.id !== notification.id);
    this._notificationService.notifications = this.notifications;
  }

  ngOnDestroy() {
    this._notificationService.notifications.forEach(item => item.read = true);
  }

  clearAll() {
    this.notifications = [];
    this._notificationService.notifications = [];
  }

}
