import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule, ErrorHandler } from '@angular/core';
import { DatePipe } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { VexModule } from '../@vex/vex.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CustomMatPaginatorIntl } from './custom-mat-paginator-int';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { DxButtonModule } from 'devextreme-angular';
import { LoginService } from '../app/services/login.service';
import { AuthGuard } from '../app/guards/auth.guard';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { GlobalService } from './services/global.service';
import { ErrorReportService } from './services/error/error-report.service';
import { CustomErrorHandler } from './services/error/custom-error-handler.service';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { SharedModule } from './shared/shared.module';
import { DialogService } from './shared/dialog/dialog.service';
import { SnackbarService } from './shared/snackbar/snackbar.service';
import { ReplyInterceptor } from './http-interceptors/reply-interceptor';
import { APP_BASE_HREF } from '@angular/common';
import { environment } from '../environments/environment';
import { ServiceWorkerModule } from '@angular/service-worker';


@NgModule({
  declarations: [AppComponent],
  imports: [

    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    DxButtonModule,
    MatSnackBarModule,
    SharedModule,
    NgxMaterialTimepickerModule,


    // Vex
    VexModule,
      // ServiceWorkerModule.register('ngsw-worker.js', {
      //   enabled: environment.production,
      //   // Register the ServiceWorker as soon as the application is stable
      //   // or after 30 seconds (whichever comes first).
      //   registrationStrategy: 'registerWhenStable:30000'
      // }),


    // ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
    ServiceWorkerModule.register('./ngsw-worker.js', { 
       enabled: environment.production,
        registrationStrategy: 'registerImmediately' })
  ],
  providers: [
    { provide: ErrorHandler, useClass: CustomErrorHandler },
    GlobalService,
    DialogService,
    SnackbarService,
    ErrorReportService,
    Title,
    DatePipe,

    {
      provide: MatPaginatorIntl,
      useClass: CustomMatPaginatorIntl
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ReplyInterceptor,
      multi: true,
    },
    // { provide: APP_BASE_HREF, useValue: '/app/minfo' },
    { provide: APP_BASE_HREF, useValue: environment.appBase },
    // { provide: APP_BASE_HREF, useValue: '/' },
    // { provide: APP_BASE_HREF, useValue: ['/app','/app/minfo', '/app/egglaying','/app/meat','/app/groups']},
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
