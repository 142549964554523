// import { LoggedInUser } from '../login/loggedin-user.model';
// export { LoggedInUser } from '../login/loggedin-user.model';
import { Subject, Subscription } from 'rxjs';

export enum GamaResponseCode {
  Ok = 0,
  Error = 500,
  PassExpired = 501,
}

export interface QBGlobalEvent {
  name: string;
  source: string;
  data: any;
}

/**
 * https://angular.io/guide/component-interaction#parent-and-children-communicate-via-a-service
 */
export class GlobalEvents {
  private globalEventCalledSource = new Subject<QBGlobalEvent>();
  private globalEventCalled$ = this.globalEventCalledSource.asObservable();

  public trigger(event: QBGlobalEvent) {
    this.globalEventCalledSource.next(event);
  }

  /**
   * The returned subscription should be unsubscribed in ngOnDestroy
   */
  public subscribe(callback: (event: QBGlobalEvent) => void): Subscription {
    return this.globalEventCalled$.subscribe((event: QBGlobalEvent) => {
      if (typeof callback === 'function') callback(event);
    });
  }
}

export class GlobalVariables {
  // backendHost = 'localhost:4200/bct/srv/';
  backendHost = 'localhost:4200';
  backendRootURL = '';
  backendURL = '';
  // currentUser: LoggedInUser = null;

  constructor(obj: Object = null) {
    if (typeof obj != 'object') {
      throw new TypeError('Invalid object');
    }
    Object.assign(this, obj);

    let loc = document.location;
    // let protocol = 'https:';
    let protocol = 'http:';
    if (loc.hostname !== 'localhost') {
      this.backendHost = loc.hostname;
      protocol = loc.protocol;
    }
    this.backendRootURL = protocol + '//' + this.backendHost + '/srv/minfo/';
    this.backendURL = this.backendRootURL;
  }
}

export class QBError extends Error {
  public message: string;
  public source: string;
  public reported: boolean;
  public errorHandlerIgnore: boolean;
  constructor(err: string) {
    super(err);
    Object.setPrototypeOf(this, QBError.prototype);//https://github.com/Microsoft/TypeScript/wiki/Breaking-Changes#extending-built-ins-like-error-array-and-map-may-no-longer-work
    this.message = err;
  }
}

export class QBBackendReportableError extends QBError {
  constructor(err: string) {
    super(err);
    Object.setPrototypeOf(this, QBBackendReportableError.prototype);//https://github.com/Microsoft/TypeScript/wiki/Breaking-Changes#extending-built-ins-like-error-array-and-map-may-no-longer-work
  }
}

export class QBErrorPostObj {
  errorObject: Object;//original exception
  message: string;
  globalVariables: Object;
  request: Object;
  response: Object;
  source: string;
  status: number;//http status
  localStorage: Storage;
  sessionStorage: Storage;
  href: string;

  constructor(obj: Object = null) {
    if (typeof obj != 'object') {
      throw new TypeError('Invalid object');
    }
    Object.assign(this, obj);
    this.localStorage = localStorage;
    this.sessionStorage = sessionStorage;
    this.href = location.href;
  }
}

export class HelperFuncs {
  static toHtml(str: any) {
    if (str && str == str.toString()) {
      return str.toString().replace(/\n/g, '<br>');
    }
    return '';
  }

  static fromHtml(str: any) {
    if (str && str == str.toString()) {
      return str.toString().replace(/<br ?\/?>/gm, '\n').replace(/<(?:.|\n)*?>/gm, '');
    }
    return '';
  }

  static strToDateObj(d: any): Date {
    let patt = /^\d{4}(-\d{2}){2}( \d{2}(:\d{2}){2})?$/;
    if (!patt.test(d)) return null;
    d = d.replace(/[- ]/g, ':').split(':');
    d[1]--;//months 0 based
    return (d.length == 3) ? new Date(Date.UTC(d[0], d[1], d[2])) : new Date(Date.UTC(d[0], d[1], d[2], d[3], d[4], d[5]));
  }

  static dateFormat(date: Date | string, format = 'DATETIME'): string {
    if (typeof date === 'string') date = this.strToDateObj(date);
    if (!this.isDate(date)) return '';
    var y = date.getFullYear();
    var M = date.getMonth() + 1;
    var d = date.getDate();
    if (format == 'DATE') return y + '-' + ((M < 10) ? '0' + String(M) : M) + '-' + ((d < 10) ? '0' + String(d) : d);
    var h = date.getHours();
    var m = date.getMinutes();
    var s = date.getSeconds();
    let str = '';
    let arr = format.split(' ');
    for (let i in arr) {
      switch (arr[i]) {
        case 'dd/mm/yyyy':
          str += ((d < 10) ? '0' + String(d) : d) + '/' + ((M < 10) ? '0' + String(M) : M) + '/' + y + ' ';
          break;
        case 'dd/mm/yy':
          str += ((d < 10) ? '0' + String(d) : d) + '/' + ((M < 10) ? '0' + String(M) : M) + '/' + String(y).substr(2) + ' ';
          break;
        case 'hh:mm:ss':
          str += ' ' + ((h < 10) ? '0' + String(h) : h) + ':' + ((m < 10) ? '0' + String(m) : m) + ':' + ((s < 10) ? '0' + String(s) : s);
          break;
        case 'hh:mm':
          str += ' ' + ((h < 10) ? '0' + String(h) : h) + ':' + ((m < 10) ? '0' + String(m) : m);
          break;
      }
    }
    if (str) return str.trim();
    return y + '-' + ((M < 10) ? '0' + String(M) : M) + '-' + ((d < 10) ? '0' + String(d) : d) + ' ' + ((h < 10) ? '0' + String(h) : h) + ':' + ((m < 10) ? '0' + String(m) : m) + ':' + ((s < 10) ? '0' + String(s) : s);
  }

  static isDate(d: any) {
    return Object.prototype.toString.call(d) === '[object Date]';
  }

  static isValidEmail(e: any) {
    var patt = /^[-a-z0-9~!$%^&*_=+}{\'?]+(\.[-a-z0-9~!$%^&*_=+}{\'?]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|mobi|[a-z][a-z])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/i;
    return patt.test(e);
  }

  static getMonthName(monthNum: any) {
    let months = ['ינואר', 'פברואר', 'מרץ', 'אפריל', 'מאי', 'יוני', 'יולי', 'אוגוסט', 'ספטמבר', 'אוקטובר', 'נובמבר', 'דצמבר'];
    if (months[monthNum - 1]) return months[monthNum - 1];
    console.error(`Inavid month number: ${monthNum}`);
    return '';
  }

  static objToArray(obj) {
    let arr = [];
    if (!obj || !(obj instanceof Object)) return arr;
    for (let propName of Object.getOwnPropertyNames(obj)) {
      arr.push(obj[propName]);
    }
    return arr;
  }

  static round(num: number, precision = 0) {
    const mul = Math.pow(10, precision);
    return Math.round(num * mul) / mul;
  }
}
