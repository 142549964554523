import { Injectable, EventEmitter } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators'
import { DateTime } from 'luxon';
import { Icon } from '@visurel/iconify-angular'
// export { Notification, ColorType } from '../../@vex/components/toolbar-notifications/interfaces/notification.interface';
import icCheckCircle from '@iconify/icons-ic/twotone-check-circle';
import icWarning from '@iconify/icons-ic/twotone-warning';

export enum ColorType {
  success = 'text-green-500',
  warning = 'text-orange-500',
  error = 'text-red-500'
}
export class Notification {

  private static id = 0;
  public id = ++Notification.id;
  public label: string;
  public icon: Icon;
  public colorClass: ColorType;
  public datetime: DateTime;
  public seen?: boolean;
  public read?: boolean;

  constructor(obj: Object = null) {

    if (typeof obj != 'object') {
      throw new TypeError('Invalid object');
    }
    Object.assign(this, obj);
  }
}

export interface NotificationConfig {
  id?: number;
  label: string;
  icon?: Icon;
  datetime?: DateTime;
  read?: boolean;
  colorClass?: ColorType;
  showSnackbar?: boolean;
}


@Injectable({
  providedIn: 'root'
})

export class NotificationService {

  // Observable string sources
  private notificationCalledSource = new Subject<NotificationConfig>();
  // Observable string streams
  public notificationCalled$ = this.notificationCalledSource.asObservable();

  private _subject = new Subject<Notification>();
  private _id = 0;
  notifications: Notification[] = [];

  constructor() { }

  getObservable(): Observable<Notification> {
    return this._subject.asObservable();
  }

  success(id?: number, label?: string, icon?: Icon, datetime?: DateTime, read?: boolean) {
    this.notifications.push(new Notification());
  }



  ////////////snir///////////////////////////////////
  push(obj: NotificationConfig) {
    const noti = new Notification(obj);
 

    if (obj.colorClass == "text-green-500"){
      return noti;
    }
    else{
      this.notifications.push(noti);
      this.notificationCalledSource.next(obj);
    return noti;
    }
  }


}
