
export const BS_COLORS = ['primary', 'secondary', 'success', 'danger', 'warning', 'info', 'light', 'dark'];
export type SnackbarType = 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'light' | 'dark';
export interface SnackbarConfig {
  type?: SnackbarType;
  timeout?: number;//seconds, 0: disabled and dismissible forced true
  dismissible?: boolean;
}

export type NotificationObj = { id: number, label: string } & { [prop: string]: any };

export type SnackbarRequestGenerator = (obj: NotificationObj, config?: SnackbarConfig) => void;
export type SnackbarTimerState = 'set' | 'finished' | 'cleared';

export class SnackbarRequest {
  private static id = 0;
  private _id = ++SnackbarRequest.id;
  private _msg: string;
  private _type: SnackbarType;
  private _timeout: number;
  private _dismissible: boolean;
  private _timer: any;
  private _timerState: string;
  private _nextDisplayState: string;
  public notiObj: NotificationObj;

  displayState: string;

  constructor(obj: NotificationObj, config?: SnackbarConfig) {
    this.msg = obj.label;
    this.initConfig(config);
    this.notiObj = obj;
  }

  initConfig(config: SnackbarConfig) {
    if (typeof config !== 'object') config = {};
    this.type = config.type;
    this.timeout = config.timeout;
    this.dismissible = config.dismissible;
  }

  set msg(str: string) {
    this._msg = this.toHtml(str);
  }
  set type(type: SnackbarType) {
    this._type = (BS_COLORS.indexOf(type) > -1) ? type : 'primary';
  }
  set timeout(sec: number) {
    this._timeout = ((typeof sec !== 'undefined') && (+sec > -1)) ? sec : 5;
  }
  set dismissible(dismissible: boolean) {
    this._dismissible = (typeof dismissible !== 'undefined') ? dismissible : true;
  }

  get id() { return this._id; }
  get msg() { return this._msg; }
  get type() { return this._type; }
  get timeout() { return this._timeout; }
  get timerState() { return this._timerState; }
  get dismissible() { return (this._dismissible || !this.timeout || this.timerState == 'cleared'); }

  setTimeout() {
    if (!this.timeout) return;
    this._timer = setTimeout(() => {
      if (this.displayState != 'dismissed') this.displayState = 'out';
      this._timerState = 'finished';
    }, this.timeout * 1000);
    this._timerState = 'set';
  }

  clearTimeout(state?: string) {
    clearTimeout(this._timer);
    this._timerState = 'cleared';
  }

  toHtml(str: any) {
    if (str && str == str.toString()) {
      return str.toString().replace(/\n/g, '<br>');
    }
    return '';
  }


}
