// import { NgModule } from '@angular/core';
// import { RouterModule, Routes, PreloadAllModules } from '@angular/router';
// import { LayoutComponent } from '../@vex/layout/layout.component';
// import { VexRoutes } from '../@vex/interfaces/vex-route.interface';
// import { AuthGuard } from './guards/auth.guard';
// import { AuthService } from './guards/auth.service';
// import { Role } from './pages/premission/role';


// const childrenRoutes: VexRoutes = [
//   {
//     path: '',
//     redirectTo: 'dashboards',
//     pathMatch: 'full',
//   },

//   {
//     path: 'dashboards',
//     canActivate: [AuthGuard],
//     loadChildren: () => import('./pages/dashboards/dashboard-analytics/dashboard-analytics.module').then(m => m.DashboardAnalyticsModule),
//   },
//   {
//     path: 'egglaying/dashboards',
//     canActivate: [AuthGuard],
//     loadChildren: () => import('./pages/dashboards/dashboard-analytics/dashboard-analytics.module').then(m => m.DashboardAnalyticsModule),
//   },
//   {
//     path: 'minfo/dashboards',
//     canActivate: [AuthGuard],
//     loadChildren: () => import('./pages/dashboards/dashboard-analytics/dashboard-analytics.module').then(m => m.DashboardAnalyticsModule),
//   },
//   {
//     path: 'minfo',
//     canActivate: [AuthGuard],
//     loadChildren: () => import('./pages/dashboards/dashboard-analytics/dashboard-analytics.module').then(m => m.DashboardAnalyticsModule),
//   },
//   {
//     path: 'meat/dashboards',
//     canActivate: [AuthGuard],
//     loadChildren: () => import('./pages/dashboards/dashboard-analytics/dashboard-analytics.module').then(m => m.DashboardAnalyticsModule),
//   },
//   {
//     path: 'dashboards',
//     children: [

//       {
//         path: 'data-grid/weighing-calves',
//         canActivate: [AuthGuard],
//         loadChildren: () => import('./pages/dashboards/weighing-calves/data-grid/data-grid.module').then(m => m.DataGridModule),

//       },
//       {
//         path: 'data-grid/calf-marketing',
//         canActivate: [AuthGuard],
//         loadChildren: () => import('./pages/dashboards/calf-marketing/data-grid/data-grid.module').then(m => m.DataGridModule),

//       },


//       // {
//       //   path: 'receipt',
//       //   // canLoad: [AuthGuard],
//       //   canActivate: [AuthGuard],
//       //   // data: {
//       //   //   roles: [
//       //   //     Role.Admin,
//       //   //   ]
//       //   // },
//       //   loadChildren: () => import('./pages/receipt/receipt.module').then(m => m.ReceiptModule),

//       // },
//       // {
//       //   path: 'cancel-receipt',
//       //   canActivate: [AuthGuard],
//       //   loadChildren: () => import('./pages/cancel-receipt/cancel-receipt.module').then(m => m.CancelReceiptModule),

//       // },
//       // {
//       //   path: 'sku',
//       //   // canLoad: [AuthGuard],
//       //   canActivate: [AuthGuard],
//       //   // data: { roles: [Role.Admin] },
//       //   loadChildren: () => import('./pages/sku/sku.module').then(m => m.SkuModule)
//       // },
//       // {
//       //   path: 'send-order',
//       //   canActivate: [AuthGuard],
//       //   loadChildren: () => import('./pages/goods-delivery/goods-delivery.module').then(m => m.GoodsDeliveryModule)
//       // },
//       // {
//       //   path: 'cancel-order',
//       //   canActivate: [AuthGuard],
//       //   loadChildren: () => import('./pages/cancel-order/cancel-order.module').then(m => m.CancelOrderModule)
//       // },
//       // {
//       //   path: 'stock-geek',
//       //   canActivate: [AuthGuard],
//       //   loadChildren: () => import('./pages/geek-stock/geek-stock.module').then(m => m.GeekStockModule)
//       // },
//       // {
//       //   path: 'stock-orshar',
//       //   canActivate: [AuthGuard],
//       //   loadChildren: () => import('./pages/orshar-stock/orshar-stock.module').then(m => m.OrsharStockModule)
//       // },
//       // {
//       //   path: 'receipt-fix-stock',
//       //   canActivate: [AuthGuard],
//       //   loadChildren: () => import('./pages/receipt-fix-stock/receipt-fix-stock.module').then(m => m.ReceiptFixStockModule)
//       // },
//     ]
//   },
//   {
//     path: 'pages',
//     children: [

//       {
//         path: 'invoice',
//         loadChildren: () => import('./pages/pages/invoice/invoice.module').then(m => m.InvoiceModule)
//       },
//       // {
//       //   path: 'error-404',
//       //   loadChildren: () => import('./pages/pages/errors/error-404/error-404.module').then(m => m.Error404Module),
//       // },
//       // {
//       //   path: 'error-500',
//       //   loadChildren: () => import('./pages/pages/errors/error-500/error-500.module').then(m => m.Error500Module),
//       // }

//     ]
//   },

//   // {
//   //   path: '**',
//   //   loadChildren: () => import('./pages/pages/errors/error-404/error-404.module').then(m => m.Error404Module),
//   // }
// ];

// const routes: Routes = [
//   {
//     path: 'minfo/login',
//     loadChildren: () => import('./pages/pages/auth/login/login.module').then(m => m.LoginModule),
//   },
//   {
//     path: 'egglaying/login',
//     loadChildren: () => import('./pages/pages/auth/login/login.module').then(m => m.LoginModule),
//   },
//   {
//     path: 'meat/login',
//     loadChildren: () => import('./pages/pages/auth/login/login.module').then(m => m.LoginModule),
//   },
//   {
//     path: 'groups/login',
//     loadChildren: () => import('./pages/pages/auth/login/login.module').then(m => m.LoginModule),
//   },
//   // {
//   //   path: 'register',
//   //   loadChildren: () => import('./pages/pages/auth/register/register.module').then(m => m.RegisterModule),
//   // },
//   {
//     path: 'forgot-password',
//     loadChildren: () => import('./pages/pages/auth/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule),
//   },
//   {
//     path: 'egglaying',
//     canActivate: [AuthGuard],
//     loadChildren: () => import('./pages/dashboards/dashboard-analytics/dashboard-analytics.module').then(m => m.DashboardAnalyticsModule),
//   },
//   {
//     path: 'minfo',
//     canActivate: [AuthGuard],
//     loadChildren: () => import('./pages/dashboards/dashboard-analytics/dashboard-analytics.module').then(m => m.DashboardAnalyticsModule),
//   },
//   {
//     path: 'meat',
//     canActivate: [AuthGuard],
//     loadChildren: () => import('./pages/dashboards/dashboard-analytics/dashboard-analytics.module').then(m => m.DashboardAnalyticsModule),
//   },
//   {
//     path: 'groups',
//     canActivate: [AuthGuard],
//     loadChildren: () => import('./pages/dashboards/dashboard-analytics/dashboard-analytics.module').then(m => m.DashboardAnalyticsModule),
//   },
//   // {
//   //   path: 'coming-soon',
//   //   loadChildren: () => import('./pages/pages/coming-soon/coming-soon.module').then(m => m.ComingSoonModule),
//   // },
//   {
//     path: '',
//     component: LayoutComponent,
//     children: childrenRoutes
//   }
// ];

// @NgModule({
//   imports: [RouterModule.forRoot(routes, {
//     // preloadingStrategy: PreloadAllModules,
//     scrollPositionRestoration: 'enabled',
//     relativeLinkResolution: 'corrected',
//     anchorScrolling: 'enabled',
//     onSameUrlNavigation: 'reload'
//   })],
//   exports: [RouterModule],
//   providers: [AuthGuard, AuthService]
// })
// export class AppRoutingModule { }
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from 'src/@vex/layout/layout.component';
import {  PreloadAllModules } from '@angular/router';
import { VexRoutes } from '../@vex/interfaces/vex-route.interface';
import { AuthGuard } from './guards/auth.guard';
import { AuthService } from './guards/auth.service';
import { Role } from './pages/premission/role';


const childrenRoutes: VexRoutes = [
  {
    path: '',
    redirectTo: 'dashboards',
    pathMatch: 'full',
  },

  {
    path: 'dashboards',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/dashboards/dashboard-analytics/dashboard-analytics.module').then(m => m.DashboardAnalyticsModule),
  },
  {
        path: 'pages',
        children: [
    
          {
            path: 'invoice',
            loadChildren: () => import('./pages/pages/invoice/invoice.module').then(m => m.InvoiceModule)
          },
          {
            path: 'error-404',
            loadChildren: () => import('./pages/pages/errors/error-404/error-404.module').then(m => m.Error404Module),
          },
          {
            path: 'error-500',
            loadChildren: () => import('./pages/pages/errors/error-500/error-500.module').then(m => m.Error500Module),
          }
    
        ]
      },
    {  path: '**',
    loadChildren: () => import('./pages/pages/errors/error-404/error-404.module').then(m => m.Error404Module),
  }];

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./pages/pages/auth/login/login.module').then(m => m.LoginModule),
  },
  {
    path: 'feed/login',
    loadChildren: () => import('./pages/pages/auth/login/login.module').then(m => m.LoginModule),
  },
  {
    path: 'egglaying/login',
    loadChildren: () => import('./pages/pages/auth/login/login.module').then(m => m.LoginModule),
  },
  {
    path: 'meat/login',
    loadChildren: () => import('./pages/pages/auth/login/login.module').then(m => m.LoginModule),
  },
  {
    path: 'minfo/login',
    loadChildren: () => import('./pages/pages/auth/login/login.module').then(m => m.LoginModule),
  },

  //  {
  //   path: 'egglaying/dashboards',
  //   component: LayoutComponent,
  //   children: childrenRoutes
  // },
  {
    path: 'groups/login',
    loadChildren: () => import('./pages/pages/auth/login/login.module').then(m => m.LoginModule),
 } 
// ,
  // {
  //   path: 'egglaying',
  //   component: LayoutComponent,
  //   children: childrenRoutes
  // },
  // {
  //   path: 'minfo',
  //   component: LayoutComponent,
  //   children: childrenRoutes
  // },
  // {
  //   path: 'meat',
  //   component: LayoutComponent,
  //   children: childrenRoutes
  // }
  // ,  {
  //   path: 'groups',
  //   component: LayoutComponent,
  //   children: childrenRoutes
  // }
  ,  {
    path: '',
    component: LayoutComponent,
    children: childrenRoutes
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    // preloadingStrategy: PreloadAllModules,
  // preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'enabled',
    relativeLinkResolution: 'corrected',
    anchorScrolling: 'enabled',
    onSameUrlNavigation: 'reload'
  })],
  exports: [RouterModule],
    providers: [AuthGuard, AuthService]

})
export class AppRoutingModule {
}
