import { Injectable, ErrorHandler } from '@angular/core';
import { ErrorReportService } from './error-report.service';

/**
* Override angular's ErrorHandler and send the log to backend.
*/
@Injectable()
export class CustomErrorHandler implements ErrorHandler {

  constructor(private err: ErrorReportService) { }

  handleError(error: any) {
    console.error(error);

    if (typeof error == 'object' && error.message) {
      this.err.reportFrontendError({ exception: error });
    } else {
      this.err.reportFrontendError({ message: error });
    }
  }
}
