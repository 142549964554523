import { Injectable } from '@angular/core';
import { DialogRequest, DialogType, DialogResponse, DialogRequestGenerator, DialogGeneralRequestGenerator } from './dialog.model';
import { Subject } from 'rxjs';

@Injectable()
export class DialogService {//https://angular.io/docs/ts/latest/cookbook/component-communication.html
  // Observable string sources
  private dialogCalledSource = new Subject<DialogRequest>();
  // Observable string streams
  public dialogCalled$ = this.dialogCalledSource.asObservable();

  public getAlertGenerator(): DialogRequestGenerator {
    return (msg: string, title?: string) => this.createRequest('ALERT', msg, title);
  }

  public getConfirmGenerator(): DialogRequestGenerator {
    return (msg: string, title?: string) => this.createRequest('CONFIRM', msg, title);
  }

  public getPromptGenerator(): DialogRequestGenerator {
    return (msg: string, title?: string) => this.createRequest('PROMPT', msg, title);
  }

  public getRequestGenerator(): DialogGeneralRequestGenerator {
    return (type: DialogType, msg: string, title?: string) => this.createRequest(type, msg, title);
  }

  public createRequest(type: DialogType, msg: string, title?: string): Promise<DialogResponse> {
    let request = new DialogRequest(type, msg, title);
    this.dialogCalledSource.next(request);
    // console.log(msg);
    return request.getPromise();
  }
}
