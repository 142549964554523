export type DialogType = 'ALERT' | 'CONFIRM' | 'PROMPT';
export type DialogResponse = boolean | string;
export type DialogGeneralRequestGenerator = (type: DialogType, msg: string, title?: string) => Promise<DialogResponse>;
export type DialogRequestGenerator = (msg: string, title?: string) => Promise<DialogResponse>;

export class DialogRequest {
  private _promise: Promise<DialogResponse>;
  private _msg: string;
  private _title: string;
  public response: DialogResponse = null;
  public dismissed = false;
  public resolve: Function;

  constructor(public type: DialogType, msg: string, title?: string) {
    this.msg = msg;
    this.title = title;
    this._promise = new Promise(resolve => {
      this.resolve = () => resolve(this.response);
    });
  }

  set msg(str: string) {
    this._msg = this.toHtml(str);
  }

  set title(str: string) {
    this._title = (str) ? this.fromHtml(str) : this._title = 'הודעת מערכת';
  }

  get msg() { return this._msg; }
  get title() { return this._title; }

  public getPromise() {
    return this._promise;
  }

  public toHtml(str: any) {
    if (str && str == str.toString()) {
      return str.toString().replace(/\n/g, '<br>');
    }
    return '';
  }

  public fromHtml(str: any) {
    if (str && str == str.toString()) {
      return str.toString().replace(/<br ?\/?>/gm, '\n').replace(/<(?:.|\n)*?>/gm, '');
    }
    return '';
  }
}
