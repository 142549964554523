import { Component, Inject, LOCALE_ID, Renderer2 } from '@angular/core';
import { ConfigName, ConfigService } from '../@vex/services/config.service';
import { MatIconRegistry } from '@angular/material/icon';
import { Settings } from 'luxon';
import { DOCUMENT } from '@angular/common';
import { Platform } from '@angular/cdk/platform';
import { NavigationService } from '../@vex/services/navigation.service';
import icLayers from '@iconify/icons-ic/twotone-layers';
import icAssigment from '@iconify/icons-ic/twotone-assignment';
import icContactSupport from '@iconify/icons-ic/twotone-contact-support';
import icDateRange from '@iconify/icons-ic/twotone-date-range';
import icLocalShipping from '@iconify/icons-ic/twotone-local-shipping';
import icInsertChartOutlined from '@iconify/icons-ic/twotone-insert-chart-outlined';

import icTab from '@iconify/icons-ic/twotone-tab';
import icHome from '@iconify/icons-ic/twotone-home';
import icChat from '@iconify/icons-ic/twotone-chat';
import icContacts from '@iconify/icons-ic/twotone-contacts';
import icAssessment from '@iconify/icons-ic/twotone-assessment';
import icLock from '@iconify/icons-ic/twotone-lock';
import icWatchLater from '@iconify/icons-ic/twotone-watch-later';
import icError from '@iconify/icons-ic/twotone-error';
import icAttachMoney from '@iconify/icons-ic/twotone-attach-money';
import icPersonOutline from '@iconify/icons-ic/twotone-person-outline';
import icReceipt from '@iconify/icons-ic/twotone-receipt';
import icHelp from '@iconify/icons-ic/twotone-help';
import icBook from '@iconify/icons-ic/twotone-book';
import icBubbleChart from '@iconify/icons-ic/twotone-bubble-chart';
import icFormatColorText from '@iconify/icons-ic/twotone-format-color-text';
import icStar from '@iconify/icons-ic/twotone-star';
import icViewCompact from '@iconify/icons-ic/twotone-view-compact';
import icEnhancedEncryption from '@iconify/icons-ic/twotone-enhanced-encryption';
import icSettings from '@iconify/icons-ic/twotone-settings';
import icPlace from '@iconify/icons-ic/twotone-place';
import { LayoutService } from '../@vex/services/layout.service';
import { OnInit } from '@angular/core';
import icUpdate from '@iconify/icons-ic/twotone-update';
import { ActivatedRoute } from '@angular/router';
import { filter } from 'rxjs/operators';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { SplashScreenService } from '../@vex/services/splash-screen.service';
import { Style, StyleService } from '../@vex/services/style.service';
import theme from '../@vex/utils/tailwindcss';
import icSearch from '@iconify/icons-ic/twotone-search';
import icFastFood from '@iconify/icons-ic/twotone-fastfood';
import icPublic from '@iconify/icons-ic/twotone-public';
import icLocalFlorist from '@iconify/icons-ic/twotone-local-florist';
import icRadioButtonChecked from '@iconify/icons-ic/twotone-radio-button-checked';
// import { environment } from '@environment';

@Component({
  selector: 'vex-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'vex';
  siteId: number;

  // environment = environment;
  userr:string;  


  constructor(private configService: ConfigService,
    private styleService: StyleService,
    private iconRegistry: MatIconRegistry,
    private renderer: Renderer2,
    private platform: Platform,
    @Inject(DOCUMENT) private document: Document,
    @Inject(LOCALE_ID) private localeId: string,
    private layoutService: LayoutService,
    private route: ActivatedRoute,
    private navigationService: NavigationService,
    private splashScreenService: SplashScreenService,
  ) {
    this.iconRegistry.setDefaultFontSetClass('iconify');
    Settings.defaultLocale = this.localeId;

    if (this.platform.BLINK) {
      this.renderer.addClass(this.document.body, 'is-blink');
    }

    this.route.queryParamMap.pipe(
      filter(queryParamMap => queryParamMap.has('rtl') && coerceBooleanProperty(queryParamMap.get('rtl')))
    ).subscribe(queryParamMap => {
      this.document.body.dir = 'rtl';
      this.configService.updateConfig({
        rtl: true
      });
    });



    this.route.queryParamMap.pipe(
      filter(queryParamMap => queryParamMap.has('layout'))
    ).subscribe(queryParamMap => this.configService.setConfig(queryParamMap.get('layout') as ConfigName));

    this.route.queryParamMap.pipe(
      filter(queryParamMap => queryParamMap.has('style'))
    ).subscribe(queryParamMap => this.styleService.setStyle(queryParamMap.get('style') as Style));





    this.userr=window.location.href;
if(this.userr.indexOf("egglaying")>=0){
  this.navigationService.items = [
    {
      type: 'link',
      label: 'דף הבית',
     route: ['/dashboards'],
    //  route: ['/egglaying/dashboards'],
      // icon: icHome

    },
    {
      type: 'subheading',
      label: 'ישומים',
      children: []
    },
  ];
 
}
else if(this.userr.indexOf("meat")>=0){
  this.navigationService.items = [
    {
      type: 'link',
      label: 'דף הבית',
     route: ['/dashboards'],
      // icon: icHome

    },
    {
      type: 'subheading',
      label: 'ישומים',
      children: []
    },
  ];
  

}
else if(this.userr.indexOf("minfo")>=0){
  this.navigationService.items = [
    {
      type: 'link',
      label: 'דף הבית',
      route: ['/dashboards'],
      // route: ['/minfo/dashboards'],
      // icon: icHome

    },
    {
      type: 'subheading',
      label: 'ישומים',
      children: []
    },
  ];
  

}
else if(this.userr.indexOf("groups")>=0){
  this.navigationService.items = [
    {
      type: 'link',
      label: 'דף הבית',
     route: ['/dashboards'],
      // icon: icHome

    },
    {
      type: 'subheading',
      label: 'ישומים',
      children: []
    },
  ];
  

}
else if(this.userr.indexOf("feed")>=0){
  this.navigationService.items = [
    {
      type: 'link',
      label: 'דף הבית',
     route: ['/dashboards'],
      // icon: icHome

    },
    {
      type: 'subheading',
      label: 'ישומים',
      children: []
    },
  ];
  

}
  }
 
}
