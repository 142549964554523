import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { PopoverService } from '../popover/popover.service';
import { ToolbarUserDropdownComponent } from './toolbar-user-dropdown/toolbar-user-dropdown.component';
import { LoginService } from '../../../app/services/login.service'
import icPerson from '@iconify/icons-ic/twotone-person';
import theme from '../../utils/tailwindcss';

@Component({
  selector: 'vex-toolbar-user',
  templateUrl: './toolbar-user.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToolbarUserComponent implements OnInit {

  dropdownOpen: boolean;
  icPerson = icPerson;
  userName: string;
  theme = theme;



  constructor(private popover: PopoverService,
    private cd: ChangeDetectorRef,
    private _loginService: LoginService) {

  }

  ngOnInit() {
    this._loginService.chckAuth().subscribe(auth => {
      if (auth) {
        this.userName = this._loginService.userName;
      }
    });

    // if (this._loginService.chckAuth()) {
    //   this.username = this._loginService.userName;
    // }

  }



  showPopover(originRef: HTMLElement) {

    this.dropdownOpen = true;
    this.cd.markForCheck();

    const popoverRef = this.popover.open({
      content: ToolbarUserDropdownComponent,
      origin: originRef,
      offsetY: 12,
      position: [
        {
          originX: 'center',
          originY: 'top',
          overlayX: 'center',
          overlayY: 'bottom'
        },
        {
          originX: 'end',
          originY: 'bottom',
          overlayX: 'end',
          overlayY: 'top',
        },
      ]
    });

    popoverRef.afterClosed$.subscribe(() => {
      this.dropdownOpen = false;
      this.cd.markForCheck();
    });
  }
}
