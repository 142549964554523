import { Injectable } from '@angular/core';
import { SnackbarRequest, SnackbarRequestGenerator, SnackbarConfig, NotificationObj } from './snackbar.model';
import { Subject } from 'rxjs';


@Injectable()
export class SnackbarService {//https://angular.io/docs/ts/latest/cookbook/component-communication.html
  // Observable string sources
  private snackbarCalledSource = new Subject<SnackbarRequest>();
  // Observable string streams
  public snackbarCalled$ = this.snackbarCalledSource.asObservable();

  public getRequestGenerator(): SnackbarRequestGenerator {
    return (obj: NotificationObj, config?: SnackbarConfig) => this.createRequest(obj, config);
  }

  public createRequest(obj: NotificationObj, config?: SnackbarConfig): void {
    let request = new SnackbarRequest(obj, config);
    this.snackbarCalledSource.next(request);
  }
}
