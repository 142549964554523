import { Renderer2 } from '@angular/core';
import { DialogService } from './dialog.service';
import { DialogRequest } from './dialog.model';

export abstract class DialogBase {
	queue: DialogRequest[] = [];
	currentRequest: DialogRequest;
	fade = false;
	show = false;//show started
	shown = false;
	hide = false;//hide started
	hidden = true;

	constructor(dialogService: DialogService, renderer: Renderer2) {
		renderer.listen('document', 'keyup', e => this.onKeyup(e));
		dialogService.dialogCalled$.subscribe(request => {
			this.queue.push(request);
			this.dialogShow();
		});
	}

	abstract triggerShow(): void;
	abstract triggerHide(): void;

	protected dialogShow(delay = 0) {
		if ((this.currentRequest && !this.currentRequest.dismissed) || this.queue.length < 1) return;
		if (this.hide) return;//dialogShow() will be called on hidden
		this.fade = true;
		this.currentRequest = this.queue.shift();
		setTimeout(() => {
			this.fade = false;
			if (!this.currentRequest.title) this.currentRequest.title = 'הודעת מערכת';
			if (this.show || this.shown) return;
			this.triggerShow();
		}, delay);
	}

	protected dismiss(response: boolean | string) {
		this.currentRequest.dismissed = true;
		if (this.currentRequest.response === null) {
			this.currentRequest.response = response;
		}
		this.currentRequest.resolve();
		if (this.queue.length < 1) {
			if (this.shown) this.triggerHide();
		} else {
			this.dialogShow(100);
		}
	}

	private onKeyup(e) {
		if (!this.shown) return;
		switch (e.keyCode) {
			case 13://enter
				this.dismiss(true);
				break;
			case 27://escape
				this.dismiss(false);
				break;
		}
	}

	onShow() {
		this.show = true;
		this.hidden = false;
	}

	onShown() {
		this.shown = true;
		this.show = false;
	}

	onHide() {
		this.shown = false;
		this.hide = true;
	}

	onHidden() {
		this.hidden = true;
		this.hide = false;
		this.dialogShow();
	}
}
