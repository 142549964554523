<button #originRef [matBadge]="countUnseenNoti()" matBadgePosition="before" matBadgeColor="warn" color="warn"
  (click)="showPopover()" [class.active]="dropdownOpen" class="button" mat-icon-button type="button">
  <mat-icon [icIcon]="icNotificationsActive">
  </mat-icon>
</button>


<!-- <button #originRef matBadge="8" matBadgePosition="before" matBadgeColor="warn" color="warn" (click)="showPopover()"
  [class.active]="dropdownOpen" class="button" mat-icon-button type="button">
  <mat-icon [icIcon]="icNotificationsActive" [ngClass]="{ 'red': _notificationService?.notifications.length >0 }">
  </mat-icon>
</button> -->


<!-- ///////snir////// -->

<!-- <button class="shtrudelBtn" (click)=snirsFunc()>@</button> -->
