import { Injectable } from '@angular/core';
import {
  DialogRequestGenerator,
  SnackbarRequestGenerator,
  SnackbarConfig
} from '../shared/index';
import { DialogService } from '../shared/dialog/dialog.service';
import { SnackbarService } from '../shared/snackbar/snackbar.service';
import { NotificationService, NotificationConfig, ColorType } from './notification.service';
import {
  GlobalVariables, GlobalEvents
  // ,LoggedInUser 
} from './qb-globals';
import { DateTime } from 'luxon';
// import { WSQueue } from './ws-queue';

export {
  GamaResponseCode,
  // LoggedInUser,
  GlobalVariables,
  QBGlobalEvent, GlobalEvents,
  QBError, QBBackendReportableError, QBErrorPostObj,
  HelperFuncs as F
} from './qb-globals';
export { DialogResponse, SnackbarConfig } from '../shared/index';
export { NotificationService, NotificationConfig, ColorType, Notification } from './notification.service';
// export { WSQueue,WSQueueItem } from './ws-queue';

@Injectable()
export class GlobalService {
  public token: string;
  public loading = false;
  public vars: GlobalVariables;

  // public events: GlobalEvents;
  public alert: DialogRequestGenerator;
  // public confirm: DialogRequestGenerator;
  // public prompt: DialogRequestGenerator;
  public snackbarGen: SnackbarRequestGenerator;
  // public queue: WSQueue;
  public connectivityLost = false;
  public debug = { routing: false, http: false, errorHandling: false };
  dataUser: { id: string, name: string }[] = [];
  customerDataUser:object=undefined;
  constructor(
    dialogService: DialogService,
    snackbarService: SnackbarService,
    private notificationService: NotificationService
  ) {
    // this.events = new GlobalEvents();
    this.alert = dialogService.getAlertGenerator();
    // this.alert('ddd');
    // this.confirm = dialogService.getConfirmGenerator();
    // this.prompt = dialogService.getPromptGenerator();
    this.snackbarGen = snackbarService.getRequestGenerator();
    // this.queue = new WSQueue();
  }

  addDataUser(item:any) {
       if (item.length==0)
    this.dataUser=[{id:"",name:""}]
    this.dataUser=item;
    console.log( this.dataUser);
    
  }
  getAllDataUser() {
    return this.dataUser;
  }

  addCustomerDataUser(item:any) {
    // if (item.length==0)
    this.customerDataUser=item;
    console.log( this.customerDataUser);
 
  }
  getAllCustomerDataUser() {
  return this.customerDataUser;
  }

  public notify(notiConf: NotificationConfig, snackbarConf?: SnackbarConfig) {


    notiConf.datetime = DateTime.local().setLocale("he");

    const noti = this.notificationService.push(notiConf);

    if (notiConf.showSnackbar) {
      this.snackbarGen(noti, snackbarConf);
    }
    // if (snackbarConf) {
    //   this.snackbarGen(noti, snackbarConf);
    // }

  }

  getToken() {
    if (window.sessionStorage) {

      this.token = sessionStorage.getItem("access_token");
    }
    return this.token;
  }

  public initVars(reset = false) {
    if (reset || !this.vars) {
      this.vars = new GlobalVariables();
    }
    return this.vars;
  }

  public getVars(reset = false): GlobalVariables {
    return this.initVars(reset);
  }

  // public getVar(varName: string): any {
  //   let g = this.initVars();
  //   if (!(varName in g)) throw new TypeError(`${varName} doesn't exist in 'GlobalVariables'`);
  //   return g[varName];
  // }

  // public initUser(data: Object) {
  //   // let u = new LoggedInUser(data);
  //   this.getVars().currentUser = u;
  //   sessionStorage.setItem('uData', JSON.stringify(u));
  // }
  
}
